var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-input" },
    [
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              ref: "input",
              class: { noBorder: !_vm.inputBorder },
              attrs: { type: "text" },
              domProps: { value: _vm.value },
            },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.listeners
        )
      ),
      _vm.icon
        ? _c("font-awesome-icon", {
            staticClass: "icon",
            attrs: { icon: ["fal", _vm.icon] },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }