import { render, staticRenderFns } from "./FilterListWithSlots.vue?vue&type=template&id=70983af9&scoped=true&"
import script from "./FilterListWithSlots.vue?vue&type=script&lang=ts&"
export * from "./FilterListWithSlots.vue?vue&type=script&lang=ts&"
import style0 from "./FilterListWithSlots.vue?vue&type=style&index=0&id=70983af9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70983af9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\7\\s\\Lcp.PensionSchemeDatabase.WebVue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70983af9')) {
      api.createRecord('70983af9', component.options)
    } else {
      api.reload('70983af9', component.options)
    }
    module.hot.accept("./FilterListWithSlots.vue?vue&type=template&id=70983af9&scoped=true&", function () {
      api.rerender('70983af9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FilterListWithSlots.vue"
export default component.exports