export default class ModalInputs {

    public Header: string;
    public Component: string;
    public ComponentData: any;

    constructor (
        header: string,
        component: string,
        componentData: any,
    ) {
        this.Header = header;
        this.Component = component;
        this.ComponentData = componentData;
    }

}
