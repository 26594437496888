var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "flexColumnBigGap" },
    [
      _vm.duiDefinition
        ? _c("DuiWrapper", {
            attrs: {
              duiDefinitionString: _vm.duiDefinition.Definition,
              duiValueString: _vm.duiDefinition.Value,
            },
            on: {
              "initial-value-set": _vm.onSelectionValueChange,
              "value-changed": _vm.onSelectionValueChange,
            },
          })
        : _vm._e(),
      !_vm.viewableSectionsData || _vm.showAuthoriseLink
        ? _c("p", { class: { authorise: _vm.showAuthoriseLink } }, [
            _vm._v(" Please visit "),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.authLink,
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
              },
              [_vm._v("LCP Authorise")]
            ),
            _vm._v(
              " to add a new scheme and/or edit the permissions to the 'Pension scheme database'. Once completed, please "
            ),
            _c(
              "a",
              {
                staticClass: "refreshLink",
                on: {
                  click: function ($event) {
                    return _vm.refresh(true)
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.refresh(true)
                  },
                },
              },
              [_vm._v("refresh")]
            ),
            _vm._v(" your scheme list for the changes to flow through. "),
          ])
        : _vm._e(),
      _vm.viewableSectionsData
        ? _c(
            "div",
            { staticClass: "flexColumnSmallGap" },
            _vm._l(_vm.viewableSectionsData, function (section, key) {
              return _c("DataSection", {
                key: key,
                attrs: { sectionTitle: section.SectionDisplayName },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "component",
                      fn: function () {
                        return [
                          _c(section.VueComponent, {
                            tag: "component",
                            attrs: { sectionData: section.SectionData },
                            on: {
                              "initial-value-set": function ($event) {
                                return _vm.onInputsUpdated($event, key)
                              },
                              "inputs-changed": function ($event) {
                                return _vm.onInputsUpdated($event, key)
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          )
        : _vm._e(),
      _c(
        "button",
        {
          attrs: { disabled: !_vm.canSubmit, type: "submit" },
          on: { click: _vm.onSubmitData },
        },
        [_vm._v("Submit")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }