

import { Vue, Component, Prop } from 'vue-property-decorator';
import ButtonWrapper from '@/components/ButtonWrapper.vue';

@Component({
    components: {
        ButtonWrapper,
    },
})

export default class ButtonIcon extends Vue {

    @Prop(String) icon?: string;
    @Prop(Boolean) disabled?: boolean;
    @Prop(Function) handleClick!: Function;

}

