

import { Component, Vue } from 'vue-property-decorator';
import ButtonIcon from '@/components/ButtonIcon.vue';
import { axiosPost } from '@/router/axiosInteractor';

@Component({
    components: {
        ButtonIcon,
    },
})

export default class PensionSchemeNav extends Vue {

    private refreshPopoverOpen = false;

    get userEmail () {
        return this.$store.state.userInfo.EmailAddress;
    }

    get adminPermission () {
        return this.$store.state.userInfo.IsAdmin;
    }

    onRefreshPopoverClick () {
        this.refreshPopoverOpen = true;
    }

    async resetUserAuthorisation () {
        this.onRefreshStart();
        await axiosPost('application/refreshPermissions', null, this.onRefreshComplete);
    }

    async resetLCPStaffMembers () {
        this.onRefreshStart();
        await axiosPost('application/refreshLCPStaff', null, this.onRefreshComplete);
    }

    onRefreshStart () {
        this.refreshPopoverOpen = false;
        this.$store.dispatch('loading', true);
    }

    onRefreshComplete () {
        this.$store.dispatch('loading', false);
    }

}

