

import { Vue, Component } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';
import { successPopUp } from '@/components/SwalWrapper';
import TextInput from '@/components/TextInput.vue';

@Component({
    components: {
        TextInput,
    },
})

export default class FeedbackPage extends Vue {

    private feedbackSubject = '';
    private feedbackMessage = '';
    private isAnonymous = false;
    private awaitingResponse = false;

    get canSubmit () {
        return this.feedbackMessage && this.feedbackSubject && !this.awaitingResponse;
    }

    async onFeedback () {
        this.awaitingResponse = true;
        await axiosPost(
            'application/feedback',
            { Subject: this.feedbackSubject, Message: this.feedbackMessage, IsAnonymous: this.isAnonymous },
            this.feedbackSent,
        );
        this.awaitingResponse = false;
    }

    feedbackSent () {
        successPopUp('Message sent');
        this.resetFeedback();
    }

    resetFeedback () {
        this.feedbackSubject = '';
        this.feedbackMessage = '';
        this.isAnonymous = false;
    }

}

