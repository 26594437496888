

import { Vue, Component, Prop } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';
import ADDDataSection from '@/views/ADDDataSection.vue';
import DataSection from '@/components/DataSection.vue';
import ModalWrapper from '@/components/ModalWrapper.vue';
import ModalInputs from './ModalInputs';

@Component({
    components: {
        ADDDataSection,
        DataSection,
        ModalWrapper,
    },
})

export default class DataSectionWithSaveEdit extends Vue {

  @Prop() readonly sectionTitle!: string;
  @Prop() readonly componentType!: string;
  @Prop({ default: null }) readonly componentData!: any;
  @Prop({ default: false }) readonly editableSection!: boolean;
  @Prop({ default: false }) readonly alwaysShowComponent!: boolean;
  @Prop() editingSectionDetails!: any;

  private showingComponent = false;
  private editMode = false;
  private unsavedChanges = false;
  private modalInputs: ModalInputs | null = null;

  get showEditCondition () {
      return this.editableSection && this.showingComponent;
  }

  get dataSectionComponentRef () {
      return `DataSectionWithSaveEdit_${this.sectionTitle}`;
  }

  get componentRef () {
      return `${this.componentType}_${this.sectionTitle}`;
  }

  get sectionBeingEditedName () {
      return this.editingSectionDetails != null ? this.editingSectionDetails.SectionDisplayName : '';
  }

  get otherSectionBeingEdited () {
      return this.editingSectionDetails != null && this.editingSectionDetails.SectionDisplayName !== this.sectionTitle;
  }

  async auditHistory () {
      await axiosPost('applications/ADD/viewAuditHistory', { SetupId: this.componentData.SetupId }, this.setAuditHistoryModal);
  }

  setAuditHistoryModal (auditHistoryData: any) {
      this.modalInputs = new ModalInputs(`${this.sectionTitle} - Audit history`, 'AuditHistoryModalComponent', auditHistoryData);
  }

  onShowOrHideRequested () {
      if (this.unsavedChanges) {
          this.modalInputs = new ModalInputs(
              `${this.sectionTitle} - Changes have not been saved`,
              'ConfirmModalComponent',
              'Closing this section will result in your changes being lost. Are you sure you want to continue?',
          );
          return;
      }

      (this.$refs[this.dataSectionComponentRef] as DataSection).showHideComponent();
      if (this.editMode) this.$emit('sectionInEdit', null);
  }

  onShowingComponentUpdate (showingComponent: boolean) {
      if (this.showingComponent && this.editMode) this.switchEditMode();
      this.showingComponent = showingComponent;
  }

  onUnsavedChanges (unsavedChanges: boolean) {
      this.unsavedChanges = unsavedChanges;
  }

  async onSaveEditClick () {
      if (this.otherSectionBeingEdited) return;

      if (this.editMode) {
          const success = await (this.$refs[this.componentRef] as any).saveReturnSuccessStatus();
          if (!success) return;

          this.unsavedChanges = false;
          this.$emit('sectionInEdit', null);
      } else {
          this.$emit('sectionInEdit', this.sectionTitle);
      }

      this.switchEditMode();
  }

  undo () {
      (this.$refs[this.componentRef] as any).onUndo();
      this.unsavedChanges = false;
      this.$emit('sectionInEdit', null);
      this.switchEditMode();
  }

  switchEditMode () {
      this.editMode = !this.editMode;
  }

  onModalClose () {
      this.modalInputs = null;
  }

  onDiscardChanges () {
      this.undo();
      this.onShowOrHideRequested();
  }

}

