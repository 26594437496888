import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import auth from '@/router/auth';
import localStorageManager from '@/store/localStorageManager';
import store from '@/store/index';
import SchemeSelector from '../views/SchemeSelector.vue';
import SchemePage from '../views/SchemePage.vue';
import FeedbackPage from '../views/FeedbackPage.vue';
import DevPage from '../views/DevPage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'SchemeSelector',
        component: SchemeSelector,
    },
    {
        path: '/scheme/:schemeId?',
        name: 'Scheme',
        component: SchemePage,
    },
    {
        path: '/contactus',
        name: 'ContactUs',
        component: FeedbackPage,
    },
    {
        path: '/devPage',
        name: 'DevPage',
        component: DevPage,
    },

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.redirectedFrom != null && to.redirectedFrom.includes('#state')) return;

    const token = await auth.getToken();
    const isHomePage = to.path === '/';

    if (!token) {
        if (!isHomePage) {
            localStorageManager.storeDirectRoute(to.path);
            next('/');
        }
    } else {
        if (store.state.userInfo === null) {
            store.dispatch('retrieveUserInfo');
        }
        
        if (isHomePage) {
            const desiredRoute = localStorageManager.getDirectRoute();
            if (desiredRoute) {
                localStorageManager.clearDirectRoute();
                next(desiredRoute);
            }
        }
    }

    next();
});

router.afterEach(async (to, from) => {
    if (to.path === '/') {
        const token = await auth.getToken();
        if (!token) {
            await auth.login();
        }
    }
});

export default router;
