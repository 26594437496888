

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component

export default class DataSection extends Vue {

    @Prop() readonly sectionTitle!: string;
    @Prop({ default: false }) readonly alwaysShowComponent!: boolean;
    @Prop({ default: false }) readonly emitHide!: boolean;

    private showComponent = false;

    created () {
        if (this.alwaysShowComponent) this.updateShowComponent(true);
    }

    onShowHideComponent () {
        if (this.showComponent && this.emitHide) {
            this.$emit('show-hide-requested');
            return;
        }

        this.showHideComponent();
    }

    showHideComponent () {
        if (this.alwaysShowComponent) return;

        this.updateShowComponent(!this.showComponent);
    }

    updateShowComponent (showComponent: boolean) {
        this.showComponent = showComponent;
        this.$emit('show-component-updated', this.showComponent);
    }

}

