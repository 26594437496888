

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component

export default class AuditHistoryModalComponent extends Vue {

    @Prop() componentData!: any;

}

