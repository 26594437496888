var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "sectionHeader",
        on: {
          click: _vm.onShowHideComponent,
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onShowHideComponent.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.sectionTitle) + " "),
        ]),
        _vm._t("header"),
        _c(
          "div",
          { staticClass: "showHideIcon" },
          [
            !_vm.alwaysShowComponent
              ? _c("font-awesome-icon", {
                  attrs: {
                    icon: [
                      "fal",
                      _vm.showComponent ? "chevron-up" : "chevron-down",
                    ],
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showComponent,
            expression: "showComponent",
          },
        ],
        staticClass: "componentContainer",
      },
      [_vm._t("component")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }