

import { Component, Vue } from 'vue-property-decorator';
import PensionSchemeNav from '@/components/PensionSchemeNav.vue';

@Component({
    components: {
        PensionSchemeNav,
    },
})

export default class PensionSchemeHeader extends Vue {

}

