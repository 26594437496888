var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modalBg" }, [
    _c("div", { staticClass: "editModal" }, [
      _c(
        "div",
        { staticClass: "modalHeader" },
        [
          _vm._v(" " + _vm._s(_vm.inputs.Header) + " "),
          _c("font-awesome-icon", {
            staticClass: "closeModal",
            attrs: { icon: ["fal", "times-circle"] },
            on: { click: _vm.closeModal },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "componentWrapper",
          class: { loading: _vm.showComponentLoading },
        },
        [
          _c(_vm.inputs.Component, {
            tag: "component",
            attrs: { componentData: _vm.inputs.ComponentData },
            on: {
              close: _vm.closeModal,
              "exit-data": _vm.emitExitData,
              showComponentLoading: _vm.onShowComponentLoading,
            },
          }),
          _vm.showComponentLoading ? _c("LoadingPage") : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }