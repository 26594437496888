var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("table", [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.componentData, function (entry, index) {
          return _c("tr", { key: index }, [
            _c("td", [_vm._v(_vm._s(entry.Name))]),
            _c("td", [_vm._v(_vm._s(entry.Time))]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("User")]),
        _c("th", [_vm._v("Date modified")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }