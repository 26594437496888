var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "loader" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: "/lcp_pension_scheme_database.svg", alt: "Logo" },
        }),
        _c("div", { staticClass: "linear-activity" }, [
          _c("div", { staticClass: "indeterminate" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }