

import equal from 'fast-deep-equal';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { AbstractClassComponent, ClassComponent } from '@lcp/dynamic-ui-typescript';
import OptionalDateSelector from '@/dynamicUIComponents/dynamic-ui-optionaldateselector.vue';

@Component({
    components: {
        OptionalDateSelector,
    },
})

export default class DuiWrapper extends Vue {

    @Prop() readonly duiDefinitionString!: string;
    @Prop() readonly duiValueString!: string;

    private duiDefinitionJson: any = null;
    private duiValueJson: any = null;

    private customTypes = {
        SchemeContactInterface: AbstractClassComponent, OptionalDateSelector: ClassComponent,
    };

    @Watch('duiDefinitionString', { immediate: true })
    onDuiDefinitionStringUpdated () {
        this.duiDefinitionJson = JSON.parse(this.duiDefinitionString);
    }

    @Watch('duiValueString', { immediate: true })
    onDuiValueStringUpdated () {
        this.duiValueJson = JSON.parse(this.duiValueString);
    }

    resetDui (resetDuiString: string) {
        this.duiValueJson = JSON.parse(resetDuiString);
    }

    @Watch('duiValueJson', { immediate: true })
    onPropertyChanged (newValue: any, oldValue: any) {
        if (equal(newValue, oldValue)) return;

        if (oldValue == null) this.$emit('initial-value-set', newValue);
        else this.$emit('value-changed', newValue);
    }

}

