import axios from 'axios';
import { errorPopUp } from '@/components/SwalWrapper';
import auth from '@/router/auth';

function getErrorOutput (error: any) {
    if (error.response) {
        if (error.response.data != null && error.response.data !== '') return error.response.data;
    }

    return error;
}

type responseFunction = (responseData: any) => any;

// eslint-disable-next-line no-use-before-define
async function axiosRequest (type: 'post', relativeUrl: string, onResponse: ResponseFunction, requestArgs?: any) {

    const token: any = await auth.getToken();
    axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;

    let success;

    await axios({
        method: type,
        url: `${process.env.VUE_APP_API_BASEURL}/api/${relativeUrl}`,
        data: requestArgs ?? {},
    }).then((response) => {
        onResponse(response.data);
        success = true;
    }).catch((error) => {
        const errorText = getErrorOutput(error);
        errorPopUp(errorText);
        success = false;
    });

    return success;
}

// eslint-disable-next-line no-use-before-define
export async function axiosPost (relativeUrl: string, requestArgs: any, onResponse: ResponseFunction) {
    const success = await axiosRequest('post', relativeUrl, onResponse, requestArgs);
    return success;
}

export type ResponseFunction = responseFunction;
