var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dynamic-ui-class dui-group dui-interface inline-grouped",
      class: _vm.localDefinition.uiClasses,
    },
    [
      _c("div", { staticClass: "input-field inline-header" }, [
        _c("label", { attrs: { for: _vm.componentId } }, [
          _vm._v(" " + _vm._s(_vm.localDefinition.name) + " "),
        ]),
      ]),
      _vm.isLcpTickBoxAvailable
        ? _c("dynamic-ui-boolean", {
            attrs: { name: "Is LCP?" },
            model: {
              value: _vm.lcpSelected,
              callback: function ($$v) {
                _vm.lcpSelected = $$v
              },
              expression: "lcpSelected",
            },
          })
        : _vm._e(),
      _c("dynamic-ui-interface", {
        attrs: { definition: _vm.localDefinition },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }