

import { Vue, Component, Prop } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';
import DuiWrapper from '@/components/DuiWrapper.vue';

@Component({
    components: {
        DuiWrapper,
    },
})

export default class ADDDataSection extends Vue {

    @Prop() sectionData!: any;

    private setupId: string | null = null;
    private duiDefinitionString: string | null = null;
    private previouslySavedJsonDataString: string | null = null;
    private editedJsonValue: any = null;
    private resettingDui = false;

    get componentRef () {
        return `ADDDataSection - ${this.sectionData.Category}`;
    }

    async created () {
        this.setupId = this.sectionData.SetupId;
        this.previouslySavedJsonDataString = this.sectionData.DuiWithData.Value;
        this.duiDefinitionString = this.sectionData.DuiWithData.Definition;
    }

    async onValueChanged (value: any) {
        if (this.resettingDui) {
            this.resettingDui = false;
            return;
        }

        this.editedJsonValue = value;
        this.$emit('inputs-changed', value);
        this.$emit('unsavedChanges', true);
    }

    onValueInitialised (value: any) {
        this.$emit('initial-value-set', value);
    }

    async saveReturnSuccessStatus () {
        if (this.editedJsonValue == null) return true;

        const currentDuiString = JSON.stringify(this.editedJsonValue);
        if (currentDuiString === this.previouslySavedJsonDataString) return true;

        const request = {
            Id: this.setupId,
            JsonData: currentDuiString,
            SchemeType: this.sectionData.schemeData.SchemeType,
            SectionCategory: this.sectionData.Category,
        };

        const success = await axiosPost('applications/ADD/updateADDSectionData', request, this.onUpdateComplete);
        return success;
    }

    onUpdateComplete (responseSavedJsonData: any) {
        this.previouslySavedJsonDataString = responseSavedJsonData;
        this.editedJsonValue = null;
    }

    onUndo () {
        if (this.previouslySavedJsonDataString == null) return;

        this.resettingDui = true;
        (this.$refs[this.componentRef] as DuiWrapper).resetDui(this.previouslySavedJsonDataString);
        this.editedJsonValue = null;
    }

}

