

import { Vue, Component, Prop } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';
import { successPopUp } from '@/components/SwalWrapper';
import { saveAs } from 'file-saver';

@Component

export default class ExportDataModalComponent extends Vue {

    async confirmExport () {
        await axiosPost('schemeQuery/exportAllSchemeData', null, this.dataExported);
    }

    dataExported (response: any) {
        const blob = this.convertBase64toBlob(response.Data, response.MimeType);
        saveAs(blob, `PSDData_${response.DateAndTime}${response.Extension}`);
        successPopUp('Export data successfull');
        this.onClose();
    }

    private convertBase64toBlob (base64Image: string, contentType: string) {
        const decodedData = window.atob(base64Image);
        const uInt8Array = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; i += 1) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    onClose () {
        this.$emit('close');
    }

}

