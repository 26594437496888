var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dynamic-ui-class dui-group inline inline-grouped",
      class: _vm.localDefinition.uiClasses,
    },
    [
      _c("div", { staticClass: "input-field inline-header" }, [
        _c("label", { attrs: { for: _vm.componentId } }, [
          _vm._v(" " + _vm._s(_vm.localDefinition.name) + " "),
        ]),
      ]),
      _c(
        "dynamic-ui-enumdropdown",
        _vm._b(
          {
            attrs: { hideLabel: true },
            model: {
              value: _vm.localDefinition.value.TrusteeType,
              callback: function ($$v) {
                _vm.$set(_vm.localDefinition.value, "TrusteeType", $$v)
              },
              expression: "localDefinition.value.TrusteeType",
            },
          },
          "dynamic-ui-enumdropdown",
          _vm.localDefinition.dropdownOptions,
          false
        )
      ),
      _vm.isSwitchOptionSelected
        ? _c(
            "dynamic-ui-text",
            _vm._b(
              {
                attrs: { hideLabel: true },
                model: {
                  value: _vm.localDefinition.value.TrusteeNameIfProfessional,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.localDefinition.value,
                      "TrusteeNameIfProfessional",
                      $$v
                    )
                  },
                  expression: "localDefinition.value.TrusteeNameIfProfessional",
                },
              },
              "dynamic-ui-text",
              _vm.localDefinition.textOptions,
              false
            )
          )
        : _vm._e(),
      _c("dynamic-ui-common-ui", {
        attrs: { definition: _vm.localDefinition },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }