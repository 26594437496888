import moment from 'moment';

const directRouteKey = 'directRoute';
export default {
    storeValue (storageKey: any, value: any) {
        localStorage.setItem(storageKey, value);
    },
    getStoredValue (storageKey: any) {
        return localStorage.getItem(storageKey);
    },
    storeDirectRoute (routeToStore: any) {
        const objectToStore = {
            route: routeToStore,
            requestTime: moment(),
        };
        const objectAsString = JSON.stringify(objectToStore);
        this.storeValue(directRouteKey, objectAsString);
    },
    getDirectRoute () {
        const storedValue = this.getStoredValue(directRouteKey);
        if (storedValue == null) return null;
        const storedObject = JSON.parse(storedValue);
        if (moment(storedObject.requestTime).add(10, 'minutes') > moment()) {
            return storedObject.route;
        }
        this.clearDirectRoute();
        return null;
    },
    clearDirectRoute () {
        localStorage.removeItem(directRouteKey);
    },
    clear () {
        localStorage.removeItem('hello');
        this.clearDirectRoute();
    },
};
