

import { Vue, Component } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';
import ButtonIcon from '@/components/ButtonIcon.vue';
import FilterListWithSlots from '@/components/FilterListWithSlots.vue';
import ModalInputs from '@/components/ModalInputs';
import ModalWrapper from '@/components/ModalWrapper.vue';

@Component({
    components: {
        ButtonIcon,
        FilterListWithSlots,
        ModalWrapper,
    },
})

export default class SchemeSelector extends Vue {

    private availableSchemes: any[] = [];
    private deleteMode = false;

    public modalInputs: ModalInputs | null = null;

    get adminPermission () {
        return this.$store.state.userInfo.IsAdmin;
    }

    async created () {
        await axiosPost('schemeQuery/getAvailableSchemes', null, this.onSchemesRefreshed);
    }

    onSchemesRefreshed (response: any) {
        this.availableSchemes = response;
        this.deleteMode = false;
    }

    async viewScheme (schemeId: any) {
        if (!this.deleteMode) this.$router.push(`scheme/${schemeId}`);
    }

    onAddNewScheme () {
        this.initialiseModal('Add new scheme...', 'AddSchemeModalComponent', null);
    }

    async exportSchemeData () {
        this.initialiseModal('Export data...', 'ExportDataModalComponent', null);
    }

    confirmDelete (scheme: any) {
        this.initialiseModal('Delete scheme...', 'DeleteConfirmationModalComponent', scheme);
    }

    initialiseModal (header: string, modalComponent: string, modalData: any) {
        this.modalInputs = new ModalInputs(header, modalComponent, modalData);
    }

    async onClose () {
        this.modalInputs = null;
        await axiosPost('schemeQuery/getAvailableSchemes', null, this.onSchemesRefreshed);
        // This means that every time the modal closes it refreshes the schemes? Is that ok?
    }

    toggleDeleteMode () {
        this.deleteMode = !this.deleteMode;
    }

    GetSchemeCountString (filteredResiltsLength: number) {

        if (filteredResiltsLength === 0) {
            return 'No schemes found';
        }

        const filteredCountMessage = `Showing ${filteredResiltsLength} of ${this.availableSchemes.length}`;
        return filteredCountMessage;
    }

}

