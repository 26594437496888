var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "nav",
    { staticClass: "flexRow" },
    [
      _c("ButtonIcon", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: "Homepage",
            expression: "'Homepage'",
          },
        ],
        attrs: {
          icon: "home-alt",
          handleClick: () => _vm.$router.push("/").catch((err) => {}),
        },
      }),
      _c("ButtonIcon", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: "Contact us",
            expression: "'Contact us'",
          },
        ],
        attrs: {
          icon: "comment-edit",
          handleClick: () => _vm.$router.push("/contactus").catch((err) => {}),
        },
      }),
      _c(
        "v-popover",
        {
          attrs: {
            placement: "bottom-end",
            offset: "18",
            popoverInnerClass: "popoverDropDownInner",
            open: _vm.refreshPopoverOpen,
          },
        },
        [
          _c("ButtonIcon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Refresh LCP details",
                expression: "'Refresh LCP details'",
              },
            ],
            attrs: { icon: "sync", handleClick: _vm.onRefreshPopoverClick },
          }),
          _c("template", { slot: "popover" }, [
            _c(
              "div",
              {
                staticClass: "dropDownContent pointer",
                on: {
                  click: _vm.resetUserAuthorisation,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.resetUserAuthorisation.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Refresh your schemes and permissions ")]
            ),
            _c(
              "div",
              {
                staticClass: "dropDownContent pointer",
                on: {
                  click: _vm.resetLCPStaffMembers,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.resetLCPStaffMembers.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Refresh LCP staff members ")]
            ),
          ]),
        ],
        2
      ),
      _vm.adminPermission
        ? _c("ButtonIcon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Dev Tests",
                expression: "'Dev Tests'",
              },
            ],
            attrs: {
              icon: "flask",
              handleClick: () =>
                _vm.$router.push("/devPage").catch((err) => {}),
            },
          })
        : _vm._e(),
      _c(
        "v-popover",
        {
          attrs: {
            placement: "bottom-end",
            offset: "18",
            popoverInnerClass: "popoverDropDownInner",
          },
        },
        [
          _c("ButtonIcon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "User info",
                expression: "'User info'",
              },
            ],
            attrs: { icon: "user-circle" },
          }),
          _c("template", { slot: "popover" }, [
            _c("div", { staticClass: "dropDownContent" }, [
              _vm._v(" " + _vm._s(_vm.userEmail) + " "),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }