var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "container flexColumnBigGap" }, [
    _c("div", { staticClass: "headerRowCentre" }, [
      _vm._v(" Dev Page - NON ADMIN SHOULD NOT HAVE ACCESS TO THIS "),
    ]),
    _c(
      "div",
      { staticClass: "pageContent flexColumnSmallGap" },
      [
        _c("DataSection", {
          attrs: { sectionTitle: "Permissions" },
          scopedSlots: _vm._u([
            {
              key: "component",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("TextInput", {
                        attrs: {
                          inputBorder: true,
                          placeholder:
                            "Enter user email to check permissions for",
                        },
                        model: {
                          value: _vm.emailToCheck,
                          callback: function ($$v) {
                            _vm.emailToCheck = $$v
                          },
                          expression: "emailToCheck",
                        },
                      }),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userPermissions,
                            expression: "userPermissions",
                          },
                        ],
                        staticClass: "messageBody",
                        domProps: { value: _vm.userPermissions },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.userPermissions = $event.target.value
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      attrs: { disabled: !_vm.canSubmit, type: "button" },
                      on: {
                        click: _vm.checkUserSchemePermissions,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.checkUserSchemePermissions.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("Check user permissions")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }