var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "user-search-wrapper" },
    [
      _c("TextInput", {
        attrs: {
          icon: _vm.inputIcon,
          inputBorder: false,
          placeholder: _vm.placeholder,
        },
        on: {
          focus: function ($event) {
            return _vm.handleInputFocus()
          },
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _c(
        "div",
        { staticClass: "response-container flexColumnBigGap" },
        [
          _vm._t(
            "default",
            function () {
              return [_c("p", [_vm._v("No Results")])]
            },
            { filteredResults: _vm.filteredResults }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }