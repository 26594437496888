import 'bootstrap/dist/css/bootstrap-grid.min.css';

import VTooltip from 'v-tooltip';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone, faMinusCircle, faPlus as fasPlus, faList, faThList, faTrashAlt, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faTimesCircle,
    faChevronLeft,
    faEdit,
    faSave,
    faChevronDown,
    faSignOut,
    faUserCircle,
    faHomeAlt,
    faSearch,
    faChevronUp,
    faTrash,
    faTrashUndo,
    faPlus,
    faCommentEdit,
    faSync,
    faArchive,
    faFlask,
    faFileExport,
    faBell } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Vue from 'vue';
import DynamicUi from '@lcp/dynamic-ui-typescript';
import dynamicUiContingentAssetSelector from '@/dynamicUIComponents/dynamic-ui-contingentassetselector.vue';
import dynamicUiTrusteeTypeSelector from '@/dynamicUIComponents/dynamic-ui-trusteetypeselector.vue';
import dynamicUiOptionalDateSelector from '@/dynamicUIComponents/dynamic-ui-optionaldateselector.vue';
import dynamicUiSchemeContactInterface from '@/dynamicUIComponents/dynamic-ui-schemecontactinterface.vue';

import App from './App.vue';
import router from './router';
import store from './store';

library.add(
    faPhone,
    faMinusCircle,
    faTimesCircle,
    faTrash,
    faTrashUndo,
    faPlus,
    faChevronLeft,
    faEdit,
    faSave,
    faChevronDown,
    faChevronUp,
    faSignOut,
    faUserCircle,
    faHomeAlt,
    faSearch,
    faCommentEdit,
    faSync,
    faArchive,
    faFlask,
    faFileExport,
    faBell,
    fasPlus,
    faList,
    faThList,
    faTrashAlt,
    faCaretDown,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(DynamicUi);
Vue.use(VTooltip);
Vue.component('dynamic-ui-contingentassetselector', dynamicUiContingentAssetSelector);
Vue.component('dynamic-ui-trusteetypeselector', dynamicUiTrusteeTypeSelector);
Vue.component('dynamic-ui-optionaldateselector', dynamicUiOptionalDateSelector);
Vue.component('dynamic-ui-schemecontactinterface', dynamicUiSchemeContactInterface);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
