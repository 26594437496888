var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dynamic-ui-class dui-group inline-grouped",
      class: _vm.definition.uiClasses,
    },
    [
      _c("div", { staticClass: "input-field inline-header" }, [
        _c("label", { attrs: { for: _vm.componentId } }, [
          _vm._v(" " + _vm._s(_vm.definition.name) + " "),
        ]),
      ]),
      _vm.showDate
        ? _c(
            "dynamic-ui-date",
            _vm._b(
              {
                attrs: { hideLabel: false },
                model: {
                  value: _vm.definition.value.Date,
                  callback: function ($$v) {
                    _vm.$set(_vm.definition.value, "Date", $$v)
                  },
                  expression: "definition.value.Date",
                },
              },
              "dynamic-ui-date",
              _vm.dateOptions,
              false
            )
          )
        : _vm._e(),
      _c(
        "dynamic-ui-boolean",
        _vm._b(
          {
            attrs: { default: true, hideLabel: false },
            model: {
              value: _vm.definition.value.RequiresDate,
              callback: function ($$v) {
                _vm.$set(_vm.definition.value, "RequiresDate", $$v)
              },
              expression: "definition.value.RequiresDate",
            },
          },
          "dynamic-ui-boolean",
          _vm.definition.dateCheckBox,
          false
        )
      ),
      _c("dynamic-ui-common-ui", { attrs: { definition: _vm.definition } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }