

import { Vue, Component, Prop } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';

@Component

export default class DeleteConfirmationModalComponent extends Vue {

    @Prop() componentData!: any;

    async confirmDelete () {
        await axiosPost('schemeCommand/delete', { SchemeId: this.componentData.SchemeId }, this.onClose);
    }

    onClose () {
        this.$emit('close');
    }

}

