

import { Vue, Component } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';
import { successPopUp } from '@/components/SwalWrapper';
import DataSection from '@/components/DataSection.vue';
import ButtonIcon from '@/components/ButtonIcon.vue';
import TextInput from '@/components/TextInput.vue';

@Component({
    components: {
        DataSection,
        ButtonIcon,
        TextInput,
    },
})

export default class DevPage extends Vue {

    private emailToCheck = '';
    private userPermissions = '';
    private awaitingResponse = false;
    private dateForNotifications = new Date();

    get canSubmit () {
        return this.emailToCheck && !this.awaitingResponse;
    }

    async sendNotifications () {
        const test : string[] = [];
        await axiosPost('application/notifications', { DateOverride: this.dateForNotifications, AdditionalDatesToGenerateFor: test }, this.onNotifications);
    }

    onNotifications () {
        successPopUp('Notifications successful');
    }

    async checkUserSchemePermissions () {
        this.awaitingResponse = true;
        await axiosPost('application/checkUserSchemePermissions', { UserEmail: this.emailToCheck }, this.permissionsCheckSent);
        this.awaitingResponse = false;
    }

    permissionsCheckSent (permissions: string) {
        this.userPermissions = `Authorised Schemes for  ${this.emailToCheck} :\n\n${permissions}`;
        successPopUp('Success');
    }

}

