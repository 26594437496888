

import { Component, Vue, Prop } from 'vue-property-decorator';

// eslint-disable-next-line no-shadow
enum Icons {
  'user-shield',
  'shield-alt',
  'users',
  'search',
  'trash-alt',
}

type IconStrings = keyof typeof Icons;

@Component({ inheritAttrs: false })

export default class TextInput extends Vue {

  @Prop() value!: string;
  @Prop() inputBorder!: boolean;
  @Prop() icon!: IconStrings;

  handleFocus (val: boolean) {
      if (!val) (this.$refs.input as HTMLElement).blur();

      this.$emit('handle-input-focus', val);
  }

  get listeners () {
      return {
          ...this.$listeners,
          input: (event: Event) => {
              this.$emit('input', (event.target as HTMLInputElement).value.trim());
          },
      };
  }

}

