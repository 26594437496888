var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.duiDefinitionJson
    ? _c("dynamic-ui", {
        attrs: {
          definitions: _vm.duiDefinitionJson,
          customTypes: _vm.customTypes,
        },
        model: {
          value: _vm.duiValueJson,
          callback: function ($$v) {
            _vm.duiValueJson = $$v
          },
          expression: "duiValueJson",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }