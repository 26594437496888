import Vue from 'vue';
import Vuex from 'vuex';
import { axiosPost } from '@/router/axiosInteractor';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: null,
        showLoading: false,
    },
    mutations: {
        setUserInfo (state, newInfo) {
            state.userInfo = newInfo;
        },
        loading (state, showLoading) {
            state.showLoading = showLoading;
        },
    },
    actions: {
        async retrieveUserInfo (context) {
            await axiosPost('application/getUserInformation', null, (response) => context.commit('setUserInfo', response));
        },
        loading (context, loading) {
            context.commit('loading', loading);
        },
    },
});
