var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("DataSection", {
        ref: _vm.dataSectionComponentRef,
        attrs: {
          sectionTitle: _vm.sectionTitle,
          alwaysShowComponent: _vm.alwaysShowComponent,
          emitHide: true,
        },
        on: {
          "show-hide-requested": _vm.onShowOrHideRequested,
          "show-component-updated": _vm.onShowingComponentUpdate,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm.showEditCondition
                  ? _c(
                      "div",
                      { staticClass: "editableSectionIcon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "archive"] },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.auditHistory.apply(null, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.unsavedChanges
                  ? _c(
                      "div",
                      { staticClass: "editableSectionIcon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "trash-undo"] },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.undo.apply(null, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showEditCondition
                  ? _c(
                      "div",
                      {
                        staticClass: "editableSectionIcon",
                        class: { disabled: _vm.otherSectionBeingEdited },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: {
                            icon: ["fal", _vm.editMode ? "save" : "edit"],
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onSaveEditClick.apply(null, arguments)
                            },
                          },
                        }),
                        _vm.otherSectionBeingEdited
                          ? _c("div", { staticClass: "toolTip" }, [
                              _vm._v(
                                "You are currently editing " +
                                  _vm._s(_vm.sectionBeingEditedName)
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "component",
            fn: function () {
              return [
                _c(_vm.componentType, {
                  ref: _vm.componentRef,
                  tag: "component",
                  attrs: { sectionData: _vm.componentData },
                  on: { unsavedChanges: _vm.onUnsavedChanges },
                }),
                _vm.editableSection && !_vm.editMode
                  ? _c("div", { staticClass: "noEditOverlay" })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.modalInputs
        ? _c("ModalWrapper", {
            attrs: { inputs: _vm.modalInputs },
            on: { close: _vm.onModalClose, "exit-data": _vm.onDiscardChanges },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }