var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "flexColumnBigGap" }, [
    _c("p", [
      _vm._v(
        "Exporting data will download the data for every scheme in the Pension Scheme Database into an Excel spreadsheet in your downloads folder."
      ),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "buttonContainer" }, [
      _c(
        "button",
        { attrs: { type: "button" }, on: { click: _vm.confirmExport } },
        [_vm._v("Export")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _c("i", [
        _vm._v("Note: Only partners and principals will be able to see the "),
        _c("b", [_vm._v("Additional information")]),
        _vm._v(
          " section for schemes that they do not have normal viewing permissions for."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }