

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component

export default class ButtonWrapper extends Vue {

  @Prop(Boolean) disabled?: boolean;
  @Prop(Function) handleClick!: Function;

  handleClickEvent () {
      if (this.disabled || this.handleClick == null) return;
      this.handleClick();
  }

  get listeners () {
      return {
          ...this.$listeners,
          click: () => {
              this.handleClickEvent();
          },
      };
  }

}

