var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "container flexColumnBigGap" }, [
    _c("div", { staticClass: "headerRowCentre" }, [_vm._v(" Contact us ")]),
    _c("div", { staticClass: "pageContent flexColumnBigGap" }, [
      _c(
        "div",
        [
          _c("dynamic-ui-boolean", {
            staticClass: "anonymous",
            attrs: { name: "Contact us anonymously?" },
            model: {
              value: _vm.isAnonymous,
              callback: function ($$v) {
                _vm.isAnonymous = $$v
              },
              expression: "isAnonymous",
            },
          }),
          _c("TextInput", {
            attrs: { inputBorder: true, placeholder: "Enter subject here" },
            model: {
              value: _vm.feedbackSubject,
              callback: function ($$v) {
                _vm.feedbackSubject = $$v
              },
              expression: "feedbackSubject",
            },
          }),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.feedbackMessage,
                expression: "feedbackMessage",
              },
            ],
            staticClass: "messageBody",
            attrs: {
              placeholder: "Enter query, issue or general feedback here",
            },
            domProps: { value: _vm.feedbackMessage },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.feedbackMessage = $event.target.value
              },
            },
          }),
        ],
        1
      ),
      _c(
        "button",
        {
          attrs: { disabled: !_vm.canSubmit, type: "submit" },
          on: { click: _vm.onFeedback },
        },
        [_vm._v("Submit")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }