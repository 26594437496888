

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component

export default class ConfirmModalComponent extends Vue {

    @Prop() componentData!: any;

    confirm () {
        this.$emit('exit-data', true);
    }

    onClose () {
        this.$emit('close');
    }

}

