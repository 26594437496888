

import { Vue, Component } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';
import DuiWrapper from '@/components/DuiWrapper.vue';
import ADDDataSection from '@/views/ADDDataSection.vue';
import DataSection from '@/components/DataSection.vue';
import OptionalDateSelector from '@/dynamicUIComponents/dynamic-ui-optionaldateselector.vue';

@Component({
    components: {
        DuiWrapper,
        ADDDataSection,
        DataSection,
        OptionalDateSelector,
    },
})

export default class AddSchemeModalComponent extends Vue {

    private duiDefinition: any = null;
    private selectedValue: any = null;
    private viewableSectionsData: any = null;
    private dataLoaded = false;
    private awaitingSubmitResponse = false;
    private sectionData: any = null;
    private showAuthoriseLink = false;
    private authLink = `${process.env.VUE_APP_AUTH_DOMAIN}/orgs/new-org?scheme=true`;

    get canSubmit () {
        return this.dataLoaded && this.viewableSectionsData && !this.awaitingSubmitResponse;
    }

    async created () {
        await this.refresh(false);
    }

    onCreated (responseData: any) {
        if (responseData === null) return;

        this.duiDefinition = responseData.SchemeInfoDui;
        this.viewableSectionsData = responseData.SectionsDui;
        this.sectionData = this.viewableSectionsData.map((element: any) => ({ category: element.SectionData.Category, schemeInputs: null }));
    }

    onSelectionValueChange (value: any) {
        this.selectedValue = value;
        this.showAuthoriseLink = this.selectedValue.SchemeId === 'Cannot find scheme?';
    }

    async onSubmitData () {
        this.awaitingSubmitResponse = true;
        const sectionDataToPost = this.sectionData.map((element: { category: any; schemeInputs: any }) => ({ Category: element.category, JsonData: JSON.stringify(element.schemeInputs) })); // this all probs needs to get changed
        await axiosPost('schemeCommand/add', { SchemeInfo: this.selectedValue, SectionData: sectionDataToPost }, this.onClose);
        this.awaitingSubmitResponse = false;
    }

    onClose (schemeId: any) {
        this.$emit('close');
        this.$router.push(`/scheme/${schemeId}`);
    }

    onInputsUpdated (data: any, index: any) {
        this.sectionData[index].schemeInputs = data;
        if (!this.dataLoaded && this.confirmAllSectionsLoaded()) this.dataLoaded = true;
    }

    confirmAllSectionsLoaded () {
        return this.sectionData.every((element: any) => element.schemeInputs !== null);
    }

    onShowAuthoriseLink () {
        this.showAuthoriseLink = true;
    }

    async refresh (refreshSchemes: boolean) {
        this.$emit('showComponentLoading', true);
        await axiosPost('schemeCommand/createSchemeDui', { RefreshSchemes: refreshSchemes }, this.onCreated);
        this.$emit('showComponentLoading', false);
    }

}

