var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "flexColumnBigGap" }, [
    _c("p", [
      _vm._v("Do you wish to delete "),
      _c("strong", [_vm._v(_vm._s(_vm.componentData.SchemeName))]),
      _vm._v(" and all of its associated data?"),
    ]),
    _c("div", { staticClass: "buttonContainer" }, [
      _c(
        "button",
        { attrs: { type: "button" }, on: { click: _vm.confirmDelete } },
        [_vm._v("Yes")]
      ),
      _c("button", { attrs: { type: "button" }, on: { click: _vm.onClose } }, [
        _vm._v("No"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }