import { render, staticRenderFns } from "./ADDDataSection.vue?vue&type=template&id=925a6798&scoped=true&"
import script from "./ADDDataSection.vue?vue&type=script&lang=ts&"
export * from "./ADDDataSection.vue?vue&type=script&lang=ts&"
import style0 from "./ADDDataSection.vue?vue&type=style&index=0&id=925a6798&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "925a6798",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\7\\s\\Lcp.PensionSchemeDatabase.WebVue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('925a6798')) {
      api.createRecord('925a6798', component.options)
    } else {
      api.reload('925a6798', component.options)
    }
    module.hot.accept("./ADDDataSection.vue?vue&type=template&id=925a6798&scoped=true&", function () {
      api.rerender('925a6798', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ADDDataSection.vue"
export default component.exports