import { Configuration } from '@azure/msal-browser';
import MSALAuthenticationService from '@/router/MSALAuthenticationService';

const configOverrides: Configuration = {
    auth: {
        clientId: process.env.VUE_APP_MSAL_AUTH_CLIENTID,
        authority: `https://login.microsoftonline.com/${process.env.VUE_APP_MSAL_AUTH_TENANTID}`,
    },
};

const scopes = {
    scopes: [
        'openid',
        process.env.VUE_APP_MSAL_AUTH_SCOPE,
    ],
};

const authService = new MSALAuthenticationService(configOverrides, scopes);

async function getToken () {
    const token = await authService.getToken();

    if (token) return token;
    return null;
}

async function login () {
    return authService.login();
}

async function logout () {
    return authService.logout();
}

export default {
    login,
    logout,
    getToken,
};
