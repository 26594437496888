

import { Vue, Component } from 'vue-property-decorator';
import { axiosPost } from '@/router/axiosInteractor';
import DataSectionWithSaveEdit from '@/components/DataSectionWithSaveEdit.vue';
import LoadingPage from '../LoadingPage.vue';

@Component({
    components: {
        DataSectionWithSaveEdit,
        LoadingPage,
    },
})

export default class SchemePage extends Vue {

    private schemeData: any = {};
    private viewableAddSectionsData: any = {};
    private editingSectionDetails: any = null;
    private showComponentLoading = false;

    get schemeId () {
        return this.schemeData.SchemeId;
    }

    get schemeType () {
        return this.schemeData.SchemeType;
    }

    async created () {
        this.showComponentLoading = true;
        await axiosPost('schemeQuery/view', { SchemeId: String(this.$route.params.schemeId) }, this.onSchemeRefreshed);
        this.showComponentLoading = false;
    }

    onSchemeRefreshed (responseData: any) {
        this.schemeData = responseData.SchemeData;
        this.viewableAddSectionsData = responseData.ViewableAddSections.map((element: { SectionData: any }) => {
            element.SectionData = ({ ...element.SectionData, schemeData: this.schemeData });
            return element;
        });
    }

    sectionInEdit (sectionTitle: string) {
        if (sectionTitle == null) this.editingSectionDetails = null;
        else this.editingSectionDetails = this.viewableAddSectionsData.find((x: any) => x.SectionDisplayName === sectionTitle);
    }

}

