import Swal, { SweetAlertIcon } from 'sweetalert2';

export default class SwalConfig {

    public Text: string;
    public Icon: SweetAlertIcon;

    constructor (
        text: string,
        icon: SweetAlertIcon,
    ) {
        this.Text = text;
        this.Icon = icon;
    }

}

export function popUp (config: SwalConfig) {
    Swal.fire({
        text: config.Text,
        icon: config.Icon,
        showCloseButton: true,
        showConfirmButton: false,
        backdrop: `
            rgba(0,0,0,.4);
            left top
            no-repeat
        `,
        customClass: {
            content: 'swalFormatting',
        },
    });
}

export function errorPopUp (errorText: string) {
    const config = new SwalConfig(errorText, 'error');
    popUp(config);
}

export function successPopUp (message: string) {
    const config = new SwalConfig(message, 'success');
    popUp(config);
}
