var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "container flexColumnBigGap" },
    [
      _c(
        "div",
        {
          staticClass: "backBtn",
          on: {
            click: function ($event) {
              return _vm.$router.push("/")
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.$router.push("/")
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "backChevron",
            attrs: { icon: ["fal", "chevron-left"] },
          }),
          _vm._v(" Back to all schemes "),
        ],
        1
      ),
      _c("div", { staticClass: "headerRowCentre" }, [
        _vm._v(" " + _vm._s(_vm.schemeData.SchemeDisplayName) + " "),
      ]),
      _c(
        "div",
        { staticClass: "flexColumnSmallGap" },
        _vm._l(_vm.viewableAddSectionsData, function (section, key) {
          return _c("DataSectionWithSaveEdit", {
            key: key,
            attrs: {
              sectionTitle: section.SectionDisplayName,
              editableSection: section.Editable,
              componentType: section.VueComponent,
              componentData: section.SectionData,
              editingSectionDetails: _vm.editingSectionDetails,
            },
            on: { sectionInEdit: _vm.sectionInEdit },
          })
        }),
        1
      ),
      _vm.showComponentLoading ? _c("LoadingPage") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }