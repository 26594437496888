var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("header", { attrs: { id: "header" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "div",
          {
            staticClass: "logo",
            on: {
              click: function ($event) {
                _vm.$router.push("/").catch((err) => {})
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                _vm.$router.push("/").catch((err) => {})
              },
            },
          },
          [
            _c("img", {
              attrs: { src: "/lcp_pension_scheme_database.svg", alt: "Logo" },
            }),
          ]
        ),
        _c("PensionSchemeNav"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }