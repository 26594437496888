

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@/components/TextInput.vue';

@Component({ components: { TextInput }, inheritAttrs: false })

export default class FilterListsWithSlots extends Vue {

  @Prop({ default: 'search' }) inputIcon?: string;
  @Prop() listArray!: any[];
  @Prop() searchBy!: string[];
  @Prop({ default: 'search' }) placeholder?: string;
  @Prop() valueOverride?: string;

  private inputValue = '';
  
  get filteredResults () {
      return this.listArray.filter((elem: Record<string, any>) => Object.keys(elem).some((item) => {
      // only filter by specified keys
          if (this.searchBy.includes(item)) {
              return elem[item]
                  .toLowerCase()
                  .includes(this.inputValue.toLowerCase());
          }
          // when no results
          return null;
      }));
  }

  // DO NOT CHANGE 😡
  handleInputFocus () {
      this.inputValue = '';
  }

  @Watch('valueOverride', { immediate: true })
  updateInputValue (newVal: string) {
      if (newVal) {
          this.inputValue = newVal;
          if (newVal === '') {
              this.handleInputFocus();
          }
      }
  }

}

