

import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalInputs from './ModalInputs';
import AddSchemeModalComponent from '../modals/AddSchemeModalComponent.vue';
import DeleteConfirmationModalComponent from '../modals/DeleteConfirmationModalComponent.vue';
import ExportDataModalComponent from '../modals/ExportDataModalComponent.vue';
import ConfirmModalComponent from '../modals/ConfirmModalComponent.vue';
import LoadingPage from '../LoadingPage.vue';
import AuditHistoryModalComponent from '../modals/AuditHistoryModalComponent.vue';

@Component({
    components: {
        AddSchemeModalComponent,
        DeleteConfirmationModalComponent,
        ExportDataModalComponent,
        ConfirmModalComponent,
        AuditHistoryModalComponent,
        LoadingPage,
    },
})

export default class ModalWrapper extends Vue {

    @Prop(ModalInputs) readonly inputs!: ModalInputs;

    private showComponentLoading = false;

    closeModal () {
        this.$emit('close');
    }

    emitExitData (exitData: any) {
        this.$emit('exit-data', exitData);
        this.closeModal();
    }

    onShowComponentLoading (showComponentLoading: boolean) {
        this.showComponentLoading = showComponentLoading;
    }

}

