var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ButtonWrapper",
    { staticClass: "iconBtn", attrs: { handleClick: _vm.handleClick } },
    [_c("font-awesome-icon", { attrs: { icon: ["fal", _vm.icon] } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }