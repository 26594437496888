var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.userLoaded
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c("PensionSchemeHeader"),
          _vm.showLoading
            ? _c("div", { staticClass: "loading" }, [_c("LoadingPage")], 1)
            : _c(
                "main",
                { staticClass: "pageWrapper" },
                [_c("router-view")],
                1
              ),
        ],
        1
      )
    : _c("LoadingPage")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }