

import { Component, Watch } from 'vue-property-decorator';
import { DateObject, DynamicUiBase } from '@lcp/dynamic-ui-typescript';

@Component

export default class DynamicUiOptionalDateSelector extends DynamicUiBase {

    @Watch('definition.value.Date', { immediate: true })
    dateValueChanged () {
        if (this.definition.value.Date && !this.definition.value.Date.day) {
            this.definition.value.Date = Object.assign(new DateObject(), this.definition.value.Date);
        }

        this.definition.value = {
            RequiresDate: this.definition.value.RequiresDate,
            Date: this.definition.value.Date,
        };
    }

    @Watch('definition.value.RequiresDate', { immediate: true })
    requiresDateChanged () {
        this.definition.value = {
            RequiresDate: this.definition.value.RequiresDate,
            Date: this.definition.value.Date,
        };
    }

    get showDate (): boolean {
        return this.definition.value.RequiresDate && (!this.definition.value.Date || this.definition.value.Date.day);
    }

    get dateOptions (): any {
        return {
            ...this.definition.dateSelector,
            max: Object.assign(new DateObject(), this.definition.dateSelector.max),
            min: Object.assign(new DateObject(), this.definition.dateSelector.min),
        };
    }

}

