var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.duiDefinitionString
    ? _c("DuiWrapper", {
        ref: _vm.componentRef,
        attrs: {
          duiDefinitionString: _vm.duiDefinitionString,
          duiValueString: _vm.previouslySavedJsonDataString,
        },
        on: {
          "value-changed": _vm.onValueChanged,
          "initial-value-set": _vm.onValueInitialised,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }