

import { Vue, Component } from 'vue-property-decorator';
import PensionSchemeHeader from '@/components/PensionSchemeHeader.vue';
import LoadingPage from './LoadingPage.vue';

@Component({
    components: {
        PensionSchemeHeader,
        LoadingPage,
    },
})

export default class App extends Vue {

    get userLoaded () {
        return this.$store.state.userInfo;
    }

    get showLoading () {
        return this.$store.state.showLoading;
    }

}

