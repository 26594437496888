

import { Component, Watch } from 'vue-property-decorator';
import { DynamicUiBase } from '@lcp/dynamic-ui-typescript';

@Component

export default class DynamicUiSchemeContactInterface extends DynamicUiBase {

    private isLcpTickBoxAvailable = false;
    private lcpSelected = false;
    private lcpOption = -1;
    private nonLcpOption = -1;
    private optionsArray: any = null;

    created () {
        if (this.localDefinition.optionsList.length !== 2) return;

        this.optionsArray = Object.keys(this.localDefinition.options).map((key) => this.localDefinition.options[key]);

        const firstOptionLcp = this.isOptionLcp(this.localDefinition.optionsList[0].name);
        const secondOptionLcp = this.isOptionLcp(this.localDefinition.optionsList[1].name);

        this.isLcpTickBoxAvailable = (firstOptionLcp || secondOptionLcp) && firstOptionLcp !== secondOptionLcp;
        this.localDefinition.hideChangeType = this.isLcpTickBoxAvailable;

        if (this.isLcpTickBoxAvailable) {
            this.lcpOption = firstOptionLcp ? 0 : 1;
            this.nonLcpOption = firstOptionLcp ? 1 : 0;
        }

        this.lcpSelected = this.isValueLcp();
    }

    isOptionLcp (optionName: string) {
        const optionFromArray = this.optionsArray.find((t: any) => t.name === optionName);
        return optionFromArray.defaultValue.isLcp;
    }

    isValueLcp () {
        const optionInList = this.localDefinition.optionsList.find((t: any) => t.id === this.localDefinition.value.$type);
        return this.isOptionLcp(optionInList.name);
    }

    @Watch('localDefinition.selectedOption')
    onSelectedOptionChange () {
        this.lcpSelected = this.isValueLcp();
    }

    @Watch('lcpSelected')
    onLcpSelectedChange (value: boolean) {
        const optionToSelect = value ? this.lcpOption : this.nonLcpOption;
        this.localDefinition.selectedOption = this.localDefinition.optionsList[optionToSelect].id;
    }

}

