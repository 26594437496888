var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "schemeWrapper" },
        [
          _c(
            "div",
            { staticClass: "schemeInnerTop" },
            [
              !_vm.deleteMode
                ? _c("ButtonIcon", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Export scheme info",
                        expression: "'Export scheme info'",
                      },
                    ],
                    attrs: {
                      icon: "file-export",
                      handleClick: _vm.exportSchemeData,
                    },
                  })
                : _vm._e(),
              !_vm.deleteMode
                ? _c("ButtonIcon", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Add new scheme",
                        expression: "'Add new scheme'",
                      },
                    ],
                    attrs: { icon: "plus", handleClick: _vm.onAddNewScheme },
                  })
                : _vm._e(),
              _vm.adminPermission &&
              _vm.availableSchemes &&
              _vm.availableSchemes.length > 0
                ? _c("ButtonIcon", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Toggle delete mode",
                        expression: "'Toggle delete mode'",
                      },
                    ],
                    attrs: {
                      icon: _vm.deleteMode ? "times-circle" : "trash",
                      handleClick: _vm.toggleDeleteMode,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("FilterListWithSlots", {
            attrs: {
              listArray: _vm.availableSchemes,
              searchBy: ["SchemeName"],
              placeholder: "Search added schemes",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ filteredResults }) {
                  return _c(
                    "div",
                    { staticClass: "flexColumnBigGap padBottom" },
                    [
                      _vm._l(filteredResults, function (scheme) {
                        return _c(
                          "div",
                          {
                            key: scheme.Id,
                            staticClass: "viewSchemeRow",
                            class: { "delete-mode": _vm.deleteMode },
                            on: {
                              click: function ($event) {
                                return _vm.viewScheme(scheme.SchemeId)
                              },
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.viewScheme(scheme.SchemeId)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(scheme.SchemeName)),
                            ]),
                            _vm.deleteMode
                              ? _c("font-awesome-icon", {
                                  staticClass: "delete-icon",
                                  attrs: { icon: ["fas", "minus-circle"] },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmDelete(scheme)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c("div", { staticClass: "noResultRow" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.GetSchemeCountString(filteredResults.length)
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    2
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.modalInputs
        ? _c("ModalWrapper", {
            attrs: { inputs: _vm.modalInputs },
            on: { close: _vm.onClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }