

import { Component } from 'vue-property-decorator';
import { DynamicUiBase } from '@lcp/dynamic-ui-typescript';

@Component

export default class DynamicUiTrusteeTypeSelector extends DynamicUiBase {

    get isSwitchOptionSelected () {
        return this.localDefinition.switchOptions.some((x: any) => x === this.localDefinition.value.TrusteeType);
    }

}

